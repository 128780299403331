import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Circle from 'ol/geom/Circle';
import MultiLineString from 'ol/geom/MultiLineString';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import {Vector as VectorSource} from 'ol/source';
import Projection from 'ol/proj/Projection';
import { fromLonLat } from 'ol/proj';
import 'ol';
import RegularShape from "ol/style/RegularShape";
import Text from "ol/style/Text";
import { Point } from "ol/geom";
import Control from 'ol/control/Control';
import {circular} from 'ol/geom/Polygon';
import { mdiCrosshairsGps } from '@mdi/js'
import Geolocation from 'ol/Geolocation';


// const image = new CircleStyle({
//   radius: 15,
//   fill: null,
//   stroke: new Stroke({color: 'red', width: 1}),
// });

const style = new Style({
  stroke: new Stroke({
    color: 'rgba(255,255,255,0.01)',
    width: 10,
  }),
  fill: new Fill({
    color: 'rgba(255,255,255,0.01)',
  }),
})
const extent = [0-3000,-6715-3000,12800+3000, 3000]
const tileExtent = [0,-6715, 16384, 16384-6715]
const pointsExtent = [-6400, -3357, 6400, 3357]
const projection = new Projection({
  code: 'pixel-map',
  units: 'pixels',
  extent: tileExtent,
});

const pointsProjection = new Projection({
  code: 'pixel-map',
  units: 'pixels',
  extent: tileExtent
})

const pointSource = new VectorSource({
  projection: pointsProjection,
  // features: new GeoJSON().readFeatures(points),
});

const lineSource = new VectorSource({
  projection: pointsProjection,
  // features: new GeoJSON().readFeatures(points),
});


for (var e of points.features) {
  var f = new Feature({geometry: new Circle(e.geometry.coordinates, 80 ),
    properties: e.properties});
  if (e.properties.id){
    f.setId(e.properties.id)
  }
  pointSource.addFeature(f)
}
for (var e of pistes.features) {
  lineSource.addFeature(new Feature({geometry: new MultiLineString(e.geometry.coordinates),
    properties: e.properties}))
}


const pointLayer = new VectorLayer({
  source: pointSource,
  style: style,
  projection: projection,
});
pointLayer.setZIndex(5);

const lineLayer = new VectorLayer({
  source: lineSource,
  style: style,
  projection: projection,
});
lineLayer.setZIndex(4);

const map = new Map({
  target: 'map',
  projection: projection,
  layers: [
    new TileLayer({
      projection: projection,
      source: new XYZ({projection: projection,
        tileSize: 128,
        url:
          './tiles/{z}/{x}/{-y}.png'
      }),
    }),
    lineLayer,
    pointLayer,
  ],
  view: new View({
    projection: projection,
    center: [6400,-3357],
    zoom: 2.8,
    maxZoom: 7,
    enableRotation: false,
    extent: extent //[-30000, -30000, 30000, 30000]
  })
});

const source = new VectorSource();
const layer = new VectorLayer({
  source: source,
});
map.addLayer(layer);


fetch('https://api.dundretlapland.com/backstatus.php')
  .then((response) => response.json())
  .then((data) => console.log(data));

// Set style of lifts to show status
// pointLayer.getSource().getFeatureById("lFJL").setStyle(TODO)

const selectStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.3)"
  }),
  stroke: new Stroke({
    color: "rgba(255, 255, 255, 0.3",
    width: 12
  })
});

const tooltip = new Style({
  text: new Text({
    font: "22px Calibri,sans-serif",
    fill: new Fill({
      color: "rgba(255,255,255, 1)"
    }),
    backgroundFill: new Fill({
      color: "rgb(0, 67, 89)"
    }),
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.9)"
    })
  }),
  geometry: new Point([])
});

const selectStyles = [selectStyle, tooltip];



var lerp = function(a, b, t) {
  return a + (b - a) * t;
};

var vec2 = {};
vec2.add = function(a, b) {
  return [ a[0] + b[0], a[1] + b[1] ];
};
vec2.sub = function(a, b) {
  return [ a[0] - b[0], a[1] - b[1] ];
};
vec2.scale = function(v, k) {
  return [ v[0] * k, v[1] * k ];
};
vec2.length = function(v) {
  return Math.sqrt(v[0]*v[0] + v[1]*v[1]);
};
vec2.distance = function(a, b) {
  var dx = b[0] - a[0];
  var dy = b[1] - a[1];
  return Math.sqrt(dx*dx + dy*dy);
};
vec2.dot = function(a, b) {
  return a[0]*b[0] + a[1]*b[1];
};
vec2.normalize = function(v) {
  var d = Math.sqrt(v[0]*v[0] + v[1]*v[1]);
  return d > 0 ? [ v[0] / d, v[1] / d ] : v;
};
vec2.area = function(a, b) {
  return a[0]*b[1] - b[0]*a[1];
};
vec2.angle = function(a, b) {
  return Math.acos(vec2.dot(a, b) / (vec2.length(a) * vec2.length(b)));
};


let Triangle = class {
  constructor(features){
    this.tri = [features[0].geometry.coordinates, features[1].geometry.coordinates, features[2].geometry.coordinates]
  }
  contains(p){
    var bary = this.toBarycentric(p)
    return (bary[0]>=0 && bary[1]>=0 && bary[2] >= 0); 
  }
  toBarycentric(p){
    var v0 = vec2.sub(this.tri[1], this.tri[0]);
    var v1 = vec2.sub(this.tri[2], this.tri[0]);
    var v2 = vec2.sub(p, this.tri[0]);
    var d00 = vec2.dot(v0, v0);
    var d01 = vec2.dot(v0, v1);
    var d11 = vec2.dot(v1, v1);
    var d20 = vec2.dot(v2, v0);
    var d21 = vec2.dot(v2, v1);
    var denom = d00 * d11 - d01 * d01;
    var v = (d11 * d20 - d01 * d21) / denom;
    var w = (d00 * d21 - d01 * d20) / denom;
    var u = 1 - v - w;
    return [u, v, w];
  }
  fromBarycentric(bary){
    var x = bary[0] * this.tri[0][0] + bary[1] * this.tri[1][0] + bary[2] * this.tri[2][0];
    var y = bary[0] * this.tri[0][1] + bary[1] * this.tri[1][1] + bary[2] * this.tri[2][1];
    return [x,y];
  }
}

let CoordinateProjection = class {
  constructor(fromPoints, toPoints, meshDims){
    this.meshDims = meshDims
    this.primaryMesh = this.makeTriangles(fromPoints)
    this.secondaryMesh = this.makeTriangles(toPoints)
  }

  transform(p){
    return this._transform(p, this.primaryMesh, this.secondaryMesh)
  }
  inverseTransform(p){
    return this._transform(p, this.secondaryMesh, this.primaryMesh)
  }

  _transform(p, prim, sec){
    var index = -1; 
    for (let i = 0; i < prim.length; i++){
      if (prim[i].contains(p)){
        index = i;
        break; 
      }
    }
    if (index <0) {
      return null; 
    }
    // Get barycentric coordinates from this triangle in the primary grid
    var bary = prim[index].toBarycentric(p);
    // Use barycentric coordinates to calculate position in the corresponding triangle in the secondary grid
    return sec[index].fromBarycentric(bary);
  }


  meshCoord(p){
    return p[0]*this.meshDims[1] + p[1]; 
  }

  makeTriangles(featureCollection){
    var triangles = []
    // Create triangle objects from point grids
    for (let row = 0; row<this.meshDims[0]-1; row++){
      for (let col = 0; col<this.meshDims[1]-1; col++){
        // Upper left triangle
        var upperLeftCoords = [[row, col], [row, col+1], [row+1, col]]
        var upperLeftFeatures = [
          featureCollection.features[this.meshCoord(upperLeftCoords[0])],
          featureCollection.features[this.meshCoord(upperLeftCoords[1])], 
          featureCollection.features[this.meshCoord(upperLeftCoords[2])]
        ]
        // Bottom right triangle
        var bottomRightCoords = [[row+1, col], [row, col+1], [row+1, col+1]]
        var bottomRightFeatures = [
          featureCollection.features[this.meshCoord(bottomRightCoords[0])],
          featureCollection.features[this.meshCoord(bottomRightCoords[1])], 
          featureCollection.features[this.meshCoord(bottomRightCoords[2])]
        ]

        triangles.push(new Triangle(upperLeftFeatures))
        triangles.push(new Triangle(bottomRightFeatures))
      }
    }
    return triangles
  }
}

const cp = new CoordinateProjection(fromPoints, toPoints, [7,11])


let selected = null;
map.on("pointermove", showPopup);
map.on("click", showPopup);

function showPopup(e) {
  if (selected !== null) {
    selected.setStyle(undefined);
    selected = null;
  }

  map.forEachFeatureAtPixel(e.pixel, function (f) {
    selected = f;
    // selectStyle.getFill().setColor(f.get("COLOR") || "#eeeeee");
    tooltip.getGeometry().setCoordinates(e.coordinate);
    tooltip.getText().setText(f.getProperties().properties.Text);
    f.setStyle(selectStyles);
    return true;
  });
}

const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: map.getView().getProjection(),
});

const locSource = new VectorSource({
  projection: pointsProjection,
});
const locLayer = new VectorLayer({
  source: locSource,
  style: new Style({
    stroke: new Stroke({
      color: '#039fe3ee',
      width: 3,
    }),
    fill: new Fill({
      color: '#039fe344',
    }),
  }),
  projection: projection,
});

map.addLayer(locLayer);

geolocation.on('change', function(evt) {
  window.console.log(geolocation.getPosition());
  var pixelcoords = cp.transform(geolocation.getPosition());
  window.console.log(pixelcoords);
  if (pixelcoords[0]) {
    if (!locLayer.getSource().getFeatureById("locCircle")){
      var f = new Feature({geometry: new Circle(pixelcoords, geolocation.getAccuracy()*10)})
      f.setId("locCircle")
      locSource.addFeature(f);
      map.getView().animate({
        center: pixelcoords,
        zoom: 5,
        duration: 500,
      });
    } else {
      var f = locLayer.getSource().getFeatureById("locCircle");
      f.setCoordinates(pixelCoords)
      f.getGeometry().setRadius(geolocation.getAccuracy()*10)
    }
  }
  

});

if (!source.isEmpty()) {
  map.getView().fit(source.getExtent(), {
    maxZoom: 5,
    duration: 500,
  });
}

const locate = document.createElement('div');
locate.className = 'ol-control ol-unselectable locate';
locate.innerHTML = `<button title="Locate me"><svg width=15 height=15 viewBox="0 0 25 25"><path d="${mdiCrosshairsGps}" fill="#555555" /></svg></button>`;
// locate.innerHTML = `<button title="Locate me">•</button>`;
locate.addEventListener('click', function () {
  locLayer.getSource().clear();
  geolocation.setTracking(!geolocation.getTracking());
});
map.addControl(
  new Control({
    element: locate,
  })
);